import { LOCALES } from 'i18n-translations/locales';

const keys = {
	[LOCALES.ENGLISH]: {
		moduleConvention: 'Serial number must start with {value}.',
		lengthConvention: 'Length must be {value} characters.',
		packagingSerialNumbersOnAllLevelsValidation: '{value1} should be same as {value2}.',
		incorrectSerialNumbersMessage: 'Please make sure to follow the correct conventions!',
		canceledDeviceTest: 'Device test canceled!',
		tryAgain: 'Try again',
		dataSubmitted: 'Data Submitted',
		certificateInstalled: 'Certificate installed successfully!',
		testingSuccessful: 'Testing successful',
		certificateNotInstalled: 'Certificate not installed!',
		certificateFailedToBeInstalled: 'Certificate failed to be installed in the device!',
		chooseSKU: 'Please choose a SKU!',
		chooseLanguage: 'Choose a language',
		switchToDarkMode: 'Switch to dark mode',
		switchToLightMode: 'Switch to light mode',
		waitingForTestingResults: 'Waiting for testing results',
		minutes: 'Minutes',
		seconds: 'Seconds',
		cancel: 'Cancel',
		testingFinished: 'Testing finished!',
		testPassed: 'Test passed!',
		testFailed: 'Test failed!',
		testFailedWithoutFailureNotes:
			'This test failed. Please check with your production line manager for next steps and how to fix/retest.',
		testAgain: 'Test again',
		failedUploadingTestingData: 'Failed uploading testing data!',
		viewFailureReasons: 'View all failure reasons',
		timeIsUp: 'Time is up!',
		timeStarted: 'Time started',
		serialNumber: 'Serial number',
		status: 'Status',
		authorizationCode: 'Authorization Code',
		matchingSerialNumbers: 'Package and label serial numbers not matching!',
		dashboard: 'Dashboard',
		users: 'Users',
		chartAnalysis: 'Chart Analysis',
		testedDevices: 'Tested Devices',
		micCalibrationTests: 'Mic Calibration Tests',
		testingApps: 'Testing Apps',
		tfsReleases: 'Tfs Releases',
		watsReports: 'Wats Reports',
		factoryFiles: 'Factory Files',
		moduleTester: 'Factory Module Tester',
		moduleMatcher: 'Factory Module Matcher',
		fullDevice: 'Full Device Tester',
		packaging: 'Packaging',
		shippingDevices: 'Shipping Devices',
		shippingRequests: 'Shipping Requests',
		createShippingRequests: 'Create Shipping Requests',
		cameraModuleRetested: 'Camera module with this SerialNumber: {serialNumber} has been already tested.',
		retestMessage: 'Do you want to retest?',
		yes: 'Yes',
		no: 'No',
	},
};

export default keys;
