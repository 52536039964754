import { useAuth } from 'oidc-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Signin = () => {
	const { userManager } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		const handleUserLoaded = () => {
			try {
                const redirectUrl = sessionStorage.getItem('redirectUrl');
                sessionStorage.removeItem('redirectUrl');
                navigate(redirectUrl || '/devices');
                userManager.events.removeUserLoaded(handleUserLoaded);
			} catch (error) {
				console.error(error);
			}
		};
		userManager.events.addUserLoaded(handleUserLoaded);
		return () => {
			userManager.events.removeUserLoaded(handleUserLoaded);
		};
	}, [userManager, navigate]);

	return <></>;
};

export default Signin;
