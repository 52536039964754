export const IDENTITY_CONFIG = {};

IDENTITY_CONFIG.scope = process.env.REACT_APP_IDSRV_SCOPE;

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
	IDENTITY_CONFIG.authority = process.env.REACT_APP_IDSRV_AUTHORITY;

	IDENTITY_CONFIG.client_id = process.env.REACT_APP_IDSRV_CLIENT_ID;

	IDENTITY_CONFIG.client_secret = process.env.REACT_APP_IDSRV_CLIENT_SECRET;

	IDENTITY_CONFIG.redirect_uri = process.env.REACT_APP_IDSRV_REDIRECT_URI;

	IDENTITY_CONFIG.login = process.env.REACT_APP_IDSRV_LOGIN;
} else {
	IDENTITY_CONFIG.authority = window.__env__.REACT_APP_IDSRV_AUTHORITY;

	IDENTITY_CONFIG.client_id = window.__env__.REACT_APP_IDSRV_CLIENT_ID;

	IDENTITY_CONFIG.client_secret = window.__env__.REACT_APP_IDSRV_CLIENT_SECRET;

	IDENTITY_CONFIG.redirect_uri = window.__env__.REACT_APP_IDSRV_REDIRECT_URI;

	IDENTITY_CONFIG.login = window.__env__.REACT_APP_IDSRV_LOGIN;
}
