import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { LOCALES } from 'i18n-translations/locales.js';
import messages from 'i18n-translations/messages/index.js';

const Provider = ({ children, locale = LOCALES.ENGLISH }) => {
	return (
		<IntlProvider locale={locale} textComponent={Fragment} messages={messages[locale]}>
			{children}
		</IntlProvider>
	);
};

export default Provider;
