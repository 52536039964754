import { LOCALES } from 'i18n-translations/locales';

const keys = {
	[LOCALES.CHINESE]: {
		moduleConvention: '序列号必须以 {value} 开头.',
		lengthConvention: '长度必须为 {value} 个字符.',
		packagingSerialNumbersOnAllLevelsValidation: '{value1} 应该与 {value2} 相同.',
		incorrectSerialNumbersMessage: '请确保遵循正确的约定!',
		canceledDeviceTest: '设备测试已取消!',
		tryAgain: '再试一次',
		dataSubmitted: '提交的数据',
		certificateInstalled: '证书安装成功!',
		testingSuccessful: '测试成功',
		certificateNotInstalled: '证书未安装!',
		certificateFailedToBeInstalled: '证书安装到设备失败!',
		chooseSKU: '请选择一个SKU!',
		chooseLanguage: '选择一种语言',
		switchToDarkMode: '切换到暗模式',
		switchToLightMode: '切换到亮模式',
		waitingForTestingResults: '等待检测结果',
		minutes: '分钟',
		seconds: '秒',
		cancel: '取消',
		testingFinished: '测试完成!',
		testPassed: '考试通过了!',
		testFailed: '测试失败!',
		testFailedWithoutFailureNotes: '这次测试失败了.请咨询您的生产线经理以了解后续步骤以及如何修复/重新测试.',
		testAgain: '再次测试',
		failedUploadingTestingData: '测试数据上传失败!',
		viewFailureReasons: '查看所有失败原因',
		timeIsUp: '时间到了!',
		timeStarted: '时间开始',
		serialNumber: '序列号',
		status: '地位',
		authorizationCode: '授权码',
		matchingSerialNumbers: '序列号不匹配!',
		dashboard: '仪表板',
		users: '用户',
		chartAnalysis: '图表分析',
		testedDevices: '测试设备',
		micCalibrationTests: '麦克风校准测试',
		testingApps: '测试应用程序',
		tfsReleases: 'Tfs 版本',
		watsReports: '瓦茨报告',
		factoryFiles: '工厂档案',
		moduleTester: '工厂模块测试仪',
		moduleMatcher: '工厂模块匹配器',
		fullDevice: '整机测试仪',
		packaging: '包装',
		shippingDevices: '运输设备',
		shippingRequests: '运输请求',
		createShippingRequests: '创建运输请求',
		cameraModuleRetested: '具有此序列号的相机模块：{serialNumber} 已经过测试',
		retestMessage: '您想重新测试吗?',
		yes: '是的',
		no: '不',
	},
};

export default keys;
