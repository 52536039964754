import dayjs from 'dayjs';
import { LOCALES } from 'i18n-translations/locales';
import { atom } from 'jotai';

export const isNavigationExpanded = atom(true);
export const stationSelected = atom(null);
export const selectedLanguage = atom(localStorage.getItem('preferredLanguage') || LOCALES.ENGLISH);
export const deviceFilters = atom({
	'filters.userSessionId': '',
	'filters.deviceFamilyId': '',
	'filters.deviceHwModuleId': '',
	'filters.serialNumber': '',
	'filters.serialNumberTo': '',
	'filters.macAddress': '',
	'filters.deviceFactoryId': '',
	'filters.date': '',
	'filters.dateTo': dayjs(new Date()).format('YYYY-MM-DD'),
	'filters.poNumber': '',
	'filters.deviceStatus': '',
	'filters.factoryTestingStatusId': '',
	'filters.bomVersion': '',
});
