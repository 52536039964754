import AppRoutes from './routes';
import { ChakraProvider, ColorModeScript, Spinner } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import I18Provider from 'i18n-translations/provider.js';
import { useAtom } from 'jotai';
import { selectedLanguage } from 'store';
import { appearanceModes } from 'enums';

function App() {
	const [startApplication, setStartApplication] = useState(true);
	const [preferredLanguage] = useAtom(selectedLanguage);

	const chakraConfig = {
		initialColorMode: appearanceModes.LIGHT_MODE,
		useSystemColorMode: false,
	};

	useEffect(() => {
		const init = async () => {
			setStartApplication(true);
		};
		init();
	}, []);

	if (!startApplication) {
		return <Spinner />;
	}

	return (
		<main>
			<I18Provider locale={preferredLanguage}>
				<ChakraProvider resetCSS extendTheme={chakraConfig}>
					<ColorModeScript initialColorMode={chakraConfig.initialColorMode} />
					<AppRoutes />
				</ChakraProvider>
			</I18Provider>
		</main>
	);
}

export default App;
