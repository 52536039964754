import { Box, Center, Text } from '@chakra-ui/react';
import { useAuth } from 'oidc-react';
import React, { useEffect } from 'react';

const Unauthorized = () => {
	const { userManager } = useAuth();

	useEffect(() => {
		setTimeout(() => {
			userManager.signoutRedirect();
		}, 3000);
	});

	return (
		<Center h='100vh'>
			<Box p='4' borderWidth='1px' borderRadius='md' boxShadow='md'>
				<Text fontSize='xl' fontWeight='bold' mb='4'>
					Unauthorized Access
				</Text>
				<Text>You do not have permission to access this page. You will be redirected to the login page shortly.</Text>
			</Box>
		</Center>
	);
};

export default Unauthorized;
