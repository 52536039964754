import { CircularProgress, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const CircularLoadingFullPage = ({ message }) => (
	<Flex
		position='absolute'
		top='0'
		left='0'
		backgroundColor='rgba(0,0,0,0.2)'
		zIndex={9999}
		height='100vh'
		width='100vw'
		display='flex'
		justifyContent='center'
		alignItems='center'
		backdropFilter='auto'
		backdropBlur='2px'
		direction='column'>
		<CircularProgress isIndeterminate zIndex={99999} />
		{message && (
			<Text color='rgb(0, 120, 212)' fontSize={20} fontWeight='bold'>
				{message}
			</Text>
		)}
	</Flex>
);

export default CircularLoadingFullPage;
