import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from 'oidc-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';
import reportWebVitals from './reportWebVitals';
import './global.css';
import { IDENTITY_CONFIG } from 'constants/auth-constants';

const oidcConfig = {
	authority: IDENTITY_CONFIG.authority,
	clientId: IDENTITY_CONFIG.client_id,
	redirectUri: IDENTITY_CONFIG.redirect_uri,
	responseMode: process.env.REACT_APP_IDSRV_RESPONSE_MODE,
	scope: IDENTITY_CONFIG.scope,
	login: IDENTITY_CONFIG.login,
	clientSecret: IDENTITY_CONFIG.client_secret,
};

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<AuthProvider {...oidcConfig}>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<Provider>
						<App />
					</Provider>
				</QueryClientProvider>
			</BrowserRouter>
		</AuthProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
